<template>
  <div class="px-2 py-5">
    <h1 class="title">Dermatology life quality index</h1>
    <b-message type="is-info">
      El cuestionario Dermatology Life Quality Index está diseñado para su uso
      en adultos, es decir, pacientes mayores de 16 años. Se explica por sí
      mismo y se puede entregar fácilmente al paciente, a quien se le pide que
      lo complete sin necesidad de una explicación detallada. Por lo general, se
      completa en uno o dos minutos.
    </b-message>

    <div class="block">
      <span>
        1. Durante la última semana, ¿cuánta <strong>picazón</strong>,
        <strong>llagas</strong>, <strong>dolor</strong> o
        <strong>escozor</strong> ha sentido en la piel?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.DLQI_P1"
            :native-value="answers.DLQI_P1.DLQI_P1_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muchísimo
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.DLQI_P1"
            :native-value="answers.DLQI_P1.DLQI_P1_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.DLQI_P1"
            :native-value="answers.DLQI_P1.DLQI_P1_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerOne"
            :name="questions.DLQI_P1"
            :native-value="answers.DLQI_P1.DLQI_P1_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Para nada
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        2. Durante la última semana, ¿qué tan <strong>avergonzado</strong> o
        <strong>cohibido</strong> ha estado debido a su piel?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.DLQI_P2"
            :native-value="answers.DLQI_P2.DLQI_P2_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muchísimo
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.DLQI_P2"
            :native-value="answers.DLQI_P2.DLQI_P2_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.DLQI_P2"
            :native-value="answers.DLQI_P2.DLQI_P2_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTwo"
            :name="questions.DLQI_P2"
            :native-value="answers.DLQI_P2.DLQI_P2_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Para nada
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        3. Durante la última semana, ¿cuánto ha interferido su piel con ir de
        <strong>compras</strong> o cuidar su <strong>hogar</strong> o
        <strong>jardín</strong>?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.DLQI_P3"
            :native-value="answers.DLQI_P3.DLQI_P3_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muchísimo
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.DLQI_P3"
            :native-value="answers.DLQI_P3.DLQI_P3_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.DLQI_P3"
            :native-value="answers.DLQI_P3.DLQI_P3_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.DLQI_P3"
            :native-value="answers.DLQI_P3.DLQI_P3_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Para nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerThree"
            :name="questions.DLQI_P3"
            :native-value="answers.DLQI_P3.DLQI_P3_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Irrelevante
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        4. Durante la última semana, ¿cuánto ha influido su piel en el
        <strong>ropa</strong> que usas?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.DLQI_P4"
            :native-value="answers.DLQI_P4.DLQI_P4_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muchísimo
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.DLQI_P4"
            :native-value="answers.DLQI_P4.DLQI_P4_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.DLQI_P4"
            :native-value="answers.DLQI_P4.DLQI_P4_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.DLQI_P4"
            :native-value="answers.DLQI_P4.DLQI_P4_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Para nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFour"
            :name="questions.DLQI_P4"
            :native-value="answers.DLQI_P4.DLQI_P4_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Irrelevante
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        5. Durante la última semana, ¿cuánto ha afectado su piel a actividades
        <strong>sociales</strong> o de <strong>ocio</strong>?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.DLQI_P5"
            :native-value="answers.DLQI_P5.DLQI_P5_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muchísimo
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.DLQI_P5"
            :native-value="answers.DLQI_P5.DLQI_P5_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.DLQI_P5"
            :native-value="answers.DLQI_P5.DLQI_P5_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.DLQI_P5"
            :native-value="answers.DLQI_P5.DLQI_P5_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Para nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerFive"
            :name="questions.DLQI_P5"
            :native-value="answers.DLQI_P5.DLQI_P5_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Irrelevante
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        6. Durante la última semana, ¿cuánto le ha dificultado la piel? para que
        hagas algún <strong>deporte</strong>?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.DLQI_P6"
            :native-value="answers.DLQI_P6.DLQI_P6_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muchísimo
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.DLQI_P6"
            :native-value="answers.DLQI_P6.DLQI_P6_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.DLQI_P6"
            :native-value="answers.DLQI_P6.DLQI_P6_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.DLQI_P6"
            :native-value="answers.DLQI_P6.DLQI_P6_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Para nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSix"
            :name="questions.DLQI_P6"
            :native-value="answers.DLQI_P6.DLQI_P6_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Irrelevante
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        7. Durante la última semana, ¿su piel le ha impedido
        <strong>trabajar</strong> o <strong>estudiar</strong>? <br />
        Si la respuesta es "No", durante la última semana, ¿cuánto ha sido un
        problema su piel en el <strong>trabajo</strong> o el
        <strong>estudio</strong>?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.DLQI_P7"
            :native-value="answers.DLQI_P7.DLQI_P7_R1"
            :disabled="queryMode"
            @input="saveTermporalSulrvey"
          >
            Si
          </b-radio>
        </div>
        <div class="column" v-if="answerSeven == 1">
          <b-radio
            v-model="answerSevenA"
            :name="questions.DLQI_P7A"
            :native-value="answers.DLQI_P7A.DLQI_P7A_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column" v-if="answerSeven == 1">
          <b-radio
            v-model="answerSevenA"
            :name="questions.DLQI_P7A"
            :native-value="answers.DLQI_P7A.DLQI_P7A_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column" v-if="answerSeven == 1">
          <b-radio
            v-model="answerSevenA"
            :name="questions.DLQI_P7A"
            :native-value="answers.DLQI_P7A.DLQI_P7A_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Para nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.DLQI_P7"
            :native-value="answers.DLQI_P7.DLQI_P7_R2"
            :disabled="queryMode"
            @input="noSelectedOption(), saveTermporalSulrvey()"
          >
            No
          </b-radio>
        </div>

        <div class="column">
          <b-radio
            v-model="answerSeven"
            :name="questions.DLQI_P7"
            :native-value="answers.DLQI_P7.DLQI_P7_R3"
            :disabled="queryMode"
            @input="noSelectedOption(), saveTermporalSulrvey()"
          >
            Irrelevante
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        8. Durante la última semana, ¿cuántos problemas ha creado su piel con su
        <strong>pareja</strong> o con alguno de sus
        <strong>amigos cercanos</strong> o <strong>familiares</strong>?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.DLQI_P8"
            :native-value="answers.DLQI_P8.DLQI_P8_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muchísimo
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.DLQI_P8"
            :native-value="answers.DLQI_P8.DLQI_P8_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.DLQI_P8"
            :native-value="answers.DLQI_P8.DLQI_P8_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.DLQI_P8"
            :native-value="answers.DLQI_P8.DLQI_P8_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Para nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerEight"
            :name="questions.DLQI_P8"
            :native-value="answers.DLQI_P8.DLQI_P8_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Irrelevante
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        9. Durante la última semana, ¿cuánto ha causado su piel alguna
        <strong>dificultad sexual</strong>?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.DLQI_P9"
            :native-value="answers.DLQI_P9.DLQI_P9_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muchísimo
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.DLQI_P9"
            :native-value="answers.DLQI_P9.DLQI_P9_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.DLQI_P9"
            :native-value="answers.DLQI_P9.DLQI_P9_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.DLQI_P9"
            :native-value="answers.DLQI_P9.DLQI_P9_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Para nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerNine"
            :name="questions.DLQI_P9"
            :native-value="answers.DLQI_P9.DLQI_P9_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Irrelevante
          </b-radio>
        </div>
      </div>
    </div>

    <hr />

    <div class="block">
      <span>
        10. Durante la última semana, ¿qué tan problemático ha sido el
        <strong>tratamiento</strong> para su piel, por ejemplo, al ensuciar su
        casa o al tomar tiempo?
      </span>

      <div class="columns mt-2">
        <div class="column has-text-right">
          <strong>Respuestas</strong>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.DLQI_P10"
            :native-value="answers.DLQI_P10.DLQI_P10_R1"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Muchísimo
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.DLQI_P10"
            :native-value="answers.DLQI_P10.DLQI_P10_R2"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Mucho
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.DLQI_P10"
            :native-value="answers.DLQI_P10.DLQI_P10_R3"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Un poco
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.DLQI_P10"
            :native-value="answers.DLQI_P10.DLQI_P10_R4"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Para nada
          </b-radio>
        </div>
        <div class="column">
          <b-radio
            v-model="answerTen"
            :name="questions.DLQI_P10"
            :native-value="answers.DLQI_P10.DLQI_P10_R5"
            @input="saveTermporalSulrvey"
            :disabled="queryMode"
          >
            Irrelevante
          </b-radio>
        </div>
      </div>
    </div>

    <b-message type="is-info">
      <strong>CÓMO INTERPRETAR EL SIGNIFICADO DE LAS PUNTUACIONES DLQI</strong>
      <br />
      Ningún efecto en la vida del paciente 0 - 1
      <br />
      Pequeño efecto en la vida del paciente 2 – 5
      <br />
      Efecto moderado en la vida del paciente 6 - 10
      <br />
      Efecto muy grande en la vida del paciente 11 - 20
      <br />
      Efecto extremadamente grande en la vida del paciente 21 - 30
    </b-message>

    <div class="block has-text-centered title">
      El resultado es: {{ score }} ({{ scoreText }})
      <span v-if="isIncompleted">(Incompleto)</span>
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { DLQI as questions } from "../../../data/questions";
import { DLQI as answers } from "../../../data/answers";

export default {
  data() {
    return {
      questions: questions,
      answers: answers,
      answer7Show: false,
      answerOne: "",
      answerTwo: "",
      answerThree: "",
      answerFour: "",
      answerFive: "",
      answerSix: "",
      answerSeven: "",
      answerSevenA: "",
      answerEight: "",
      answerNine: "",
      answerTen: "",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "DlqiList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerTwo = this.$route.params.temporalData.answerTwo;
      this.answerThree = this.$route.params.temporalData.answerThree;
      this.answerFour = this.$route.params.temporalData.answerFour;
      this.answerFive = this.$route.params.temporalData.answerFive;
      this.answerSix = this.$route.params.temporalData.answerSix;
      this.answer7Show = this.$route.params.temporalData.answer7Show;
      this.answerSeven = this.$route.params.temporalData.answerSeven;
      this.answerSevenA = this.$route.params.temporalData.answerSevenA;
      this.answerEight = this.$route.params.temporalData.answerEight;
      this.answerNine = this.$route.params.temporalData.answerNine;
      this.answerTen = this.$route.params.temporalData.answerTen;
    }
    if (!this.$route.params.temporalData && !this.$route.params.surveyId) {
      this.initialAnswer();
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      return (
        parseInt(this.answerOne || 0) +
          parseInt(this.answerTwo || 0) +
          parseInt(this.answerThree || 0) +
          parseInt(this.answerFour || 0) +
          parseInt(this.answerFive || 0) +
          parseInt(this.answerSix || 0) +
          parseInt(this.answerSeven || 0) +
          parseInt(this.answerSevenA || 0) +
          parseInt(this.answerEight || 0) +
          parseInt(this.answerNine || 0) +
          parseInt(this.answerTen || 0) || 0
      );
    },
    scoreText() {
      let text = "";
      if (this.score >= 0 && this.score <= 1) {
        text = "Ningún efecto en la vida del paciente";
      }

      if (this.score >= 2 && this.score <= 5) {
        text = "Pequeño efecto en la vida del paciente";
      }

      if (this.score >= 6 && this.score <= 10) {
        text = "Efecto moderado en la vida del paciente";
      }

      if (this.score >= 11 && this.score <= 20) {
        text = "Efecto muy grande en la vida del paciente";
      }

      if (this.score >= 21 && this.score <= 30) {
        text = "Efecto extremadamente grande en la vida del paciente";
      }

      return text;
    },
    isIncompleted() {
      return (
        this.answerOne === "" ||
        this.answerTwo === "" ||
        this.answerThree === "" ||
        this.answerFour === "" ||
        this.answerFive === "" ||
        this.answerSix === "" ||
        this.answerSeven === "" ||
        (this.answerSeven == 1 && this.answerSevenA === "") ||
        this.answerEight === "" ||
        this.answerNine === "" ||
        this.answerTen === ""
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    changeAnswer7BoolOption() {
      this.answerSevenA = "";
    },
    noSelectedOption() {
      this.answerSevenA = "";
    },
    cleanForms() {
      this.answerOne = "";
      this.answerTwo = "";
      this.answerThree = "";
      this.answerFour = "";
      this.answerFive = "";
      this.answerSix = "";
      this.answerSeven = "";
      this.answerSevenA = "";
      this.answerEight = "";
      this.answerNine = "";
      this.answerTen = "";
      this.answer7Show = false;
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerTwo = this.answerTwo;
      this.restoreData.answerThree = this.answerThree;
      this.restoreData.answerFour = this.answerFour;
      this.restoreData.answerFive = this.answerFive;
      this.restoreData.answerSix = this.answerSix;
      this.restoreData.answerSevenA = this.answerSevenA;
      this.restoreData.answerSeven = this.answerSeven;
      this.restoreData.answerEight = this.answerEight;
      this.restoreData.answerNine = this.answerNine;
      this.restoreData.answerTen = this.answerTen;

      this.initialAnswer();
      this.clearTemporalSurveys();

      this.showUndoDialog("Se limpiaron los datos de  la encuesta DLQI.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "DLQI",
          result: String(this.score),
          appreciation: this.scoreText,
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.initialAnswer();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = null;
      let question2 = null;
      let question3 = null;
      let question4 = null;
      let question5 = null;
      let question6 = null;
      let question7 = null;
      let question7A = null;
      let question8 = null;
      let question9 = null;
      let question10 = null;

      Object.keys(this.answers[questions.DLQI_P1]).forEach((key) => {
        if (this.answers[questions.DLQI_P1][key] == this.answerOne) {
          question1 = {
            questionCode: questions.DLQI_P1,
            answerCode: key,
            value: this.answerOne,
          };
        }
      });

      Object.keys(this.answers[questions.DLQI_P2]).forEach((key) => {
        if (this.answers[questions.DLQI_P2][key] == this.answerTwo) {
          question2 = {
            questionCode: questions.DLQI_P2,
            answerCode: key,
            value: this.answerTwo,
          };
        }
      });

      Object.keys(this.answers[questions.DLQI_P3]).forEach((key) => {
        if (this.answers[questions.DLQI_P3][key] == this.answerThree) {
          question3 = {
            questionCode: questions.DLQI_P3,
            answerCode: key,
            value: this.answerThree,
          };
        }
      });

      Object.keys(this.answers[questions.DLQI_P4]).forEach((key) => {
        if (this.answers[questions.DLQI_P4][key] == this.answerFour) {
          question4 = {
            questionCode: questions.DLQI_P4,
            answerCode: key,
            value: this.answerFour,
          };
        }
      });

      Object.keys(this.answers[questions.DLQI_P5]).forEach((key) => {
        if (this.answers[questions.DLQI_P5][key] == this.answerFive) {
          question5 = {
            questionCode: questions.DLQI_P5,
            answerCode: key,
            value: this.answerFive,
          };
        }
      });

      Object.keys(this.answers[questions.DLQI_P6]).forEach((key) => {
        if (this.answers[questions.DLQI_P6][key] == this.answerSix) {
          question6 = {
            questionCode: questions.DLQI_P6,
            answerCode: key,
            value: this.answerSix,
          };
        }
      });

      Object.keys(this.answers[questions.DLQI_P7]).forEach((key) => {
        if (this.answers[questions.DLQI_P7][key] == this.answerSeven) {
          question7 = {
            questionCode: questions.DLQI_P7,
            answerCode: key,
            value: this.answerSeven == null ? "0" : this.answerSeven,
          };
        }
      });

      if (this.answerSeven == 1) {
        Object.keys(this.answers[questions.DLQI_P7A]).forEach((key) => {
          if (this.answers[questions.DLQI_P7A][key] == this.answerSevenA) {
            question7A = {
              questionCode: questions.DLQI_P7A,
              answerCode: key,
              value: this.answerSevenA,
            };
          }
        });
      }

      Object.keys(this.answers[questions.DLQI_P8]).forEach((key) => {
        if (this.answers[questions.DLQI_P8][key] == this.answerEight) {
          question8 = {
            questionCode: questions.DLQI_P8,
            answerCode: key,
            value: this.answerEight,
          };
        }
      });

      Object.keys(this.answers[questions.DLQI_P9]).forEach((key) => {
        if (this.answers[questions.DLQI_P9][key] == this.answerNine) {
          question9 = {
            questionCode: questions.DLQI_P9,
            answerCode: key,
            value: this.answerNine,
          };
        }
      });

      Object.keys(this.answers[questions.DLQI_P10]).forEach((key) => {
        if (this.answers[questions.DLQI_P10][key] == this.answerTen) {
          question10 = {
            questionCode: questions.DLQI_P10,
            answerCode: key,
            value: this.answerTen,
          };
        }
      });

      let answerConsolidate = [
        question1,
        question2,
        question3,
        question4,
        question5,
        question6,
        question7,
        question7A,
        question8,
        question9,
        question10,
      ];
      let answersReturn = [];
      answerConsolidate.forEach((object) => {
        if (object) {
          answersReturn.push(object);
        }
      });

      return answersReturn;
    },
    validate() {
      if (!this.isIncompleted) {
        this.save();
      } else {
        this.showToast("Por favor completa la encuesta.", "is-warning", 3000);
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerTwo = this.restoreData.answerTwo;
      this.answerThree = this.restoreData.answerThree;
      this.answerFour = this.restoreData.answerFour;
      this.answerFive = this.restoreData.answerFive;
      this.answerSix = this.restoreData.answerSix;
      this.answer7Show = this.restoreData.answer7Show;
      this.answerSeven = this.restoreData.answerSeven;
      this.answerSevenA = this.restoreData.answerSevenA;
      this.answerEight = this.restoreData.answerEight;
      this.answerNine = this.restoreData.answerNine;
      this.answerTen = this.restoreData.answerTen;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "DLQI",
          route: "Dlqi",
        },
        survey: {
          answerOne: this.answerOne,
          answerTwo: this.answerTwo,
          answerThree: this.answerThree,
          answerFour: this.answerFour,
          answerFive: this.answerFive,
          answerSix: this.answerSix,
          answer7Show: this.answer7Show,
          answerSeven: this.answerSeven,
          answerSevenA: this.answerSevenA,
          answerEight: this.answerEight,
          answerNine: this.answerNine,
          answerTen: this.answerTen,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "DLQI_P1") {
          this.answerOne = survey.value;
        }

        if (survey.question.code == "DLQI_P2") {
          this.answerTwo = survey.value;
        }

        if (survey.question.code == "DLQI_P3") {
          this.answerThree = survey.value;
        }

        if (survey.question.code == "DLQI_P4") {
          this.answerFour = survey.value;
        }

        if (survey.question.code == "DLQI_P5") {
          this.answerFive = survey.value;
        }

        if (survey.question.code == "DLQI_P6") {
          this.answerSix = survey.value;
        }

        if (survey.question.code == "DLQI_P7") {
          this.answerSeven = survey.value;
        }

        if (survey.question.code == "DLQI_P7A") {
          this.answerSevenA = survey.value;
        }

        if (survey.question.code == "DLQI_P8") {
          this.answerEight = survey.value;
        }

        if (survey.question.code == "DLQI_P9") {
          this.answerNine = survey.value;
        }

        if (survey.question.code == "DLQI_P10") {
          this.answerTen = survey.value;
        }
      });
    },
    initialAnswer() {
      this.answerOne = this.answers.DLQI_P1.DLQI_P1_R4;
      this.answerTwo = this.answers.DLQI_P2.DLQI_P2_R4;
      this.answerThree = this.answers.DLQI_P3.DLQI_P3_R5;
      this.answerFour = this.answers.DLQI_P4.DLQI_P4_R5;
      this.answerFive = this.answers.DLQI_P5.DLQI_P5_R5;
      this.answerSix = this.answers.DLQI_P6.DLQI_P6_R5;
      this.answerSeven = this.answers.DLQI_P7.DLQI_P7_R3;
      this.answerSevenA = this.answers.DLQI_P7A.DLQI_P7A_R3;
      this.answerEight = this.answers.DLQI_P8.DLQI_P8_R5;
      this.answerNine = this.answers.DLQI_P9.DLQI_P9_R5;
      this.answerTen = this.answers.DLQI_P10.DLQI_P10_R5;
    },
  },
};
</script>
